import React from "react";

function ContactMap() {
  return (
    <>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13971.864384772212!2d77.10284245600869!3d28.89935555831802!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m3!3e6!4m0!4m0!5e0!3m2!1sen!2sin!4v1705146183348!5m2!1sen!2sin"
        width="100%"
        height="450"
        id="frame1" 
        allowFullScreen
      ></iframe>
    </>
  );
}

export default ContactMap;

import React from "react";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
// import "lightbox.js-react/dist/index.css";
import { Helmet } from "react-helmet";
import { useGetgallaryListQuery } from "../products/productSlice";
import { ImgesData } from "../home/proImgs/ImgesData";
import p1  from "../../assets/img/gallery/p1.jpg";
import  p2  from "../../assets/img/gallery/p2.jpg";
import  p3  from "../../assets/img/gallery/p3.jpg";
import  p4  from "../../assets/img/gallery/p4.jpg";
import  p5  from "../../assets/img/gallery/p5.png";
import  p6  from "../../assets/img/gallery/p6.png";

function PhotoGallery() {
  const { data, isLoading } = useGetgallaryListQuery()
  return (
    <>
      <Helmet>
        <title>Photo Gallery | Fertilizer Multi Vendor</title>
        <meta
          name="keyword"
          content="Fertilizer, Agricultural, Seeds, Machinery, Nutrition"
        />
        <meta
          name="description"
          content="Buy Agricultural Products and Machinery Online at Unity Infra. We Offering broad range of Seeds, Plant Nutrition, Plant Protection and Agri Implements."
        />
      </Helmet>
      <Breadcrumb title="Photo Gallery" />
      <section className="photoGallery p-30">
        <div className="container">
          {isLoading && <div className="preloaderCount">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>}
          {/* Gallery */}
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-4 mb-lg-0">
              <div className="pageTitle">
                <h4>Our Photo Gallery</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
                  excepturi fuga nemo vitae possimus repellat, praesentium
                  temporibus officiis, accusamus, labore nostrum aspernatur
                  dolore voluptatibus nesciunt placeat error odio atque ea.
                </p>
              </div>
            </div>
            {/* Gallery */}

            <div className="row">


              {/* {data && data.map((item, i) => {
                return <div className="col-lg-3" key={item._id} data-bs-toggle="modal" data-bs-target="#exampleModalPhoto">
                  <img
                    style={{ width: "100%" }}
                    src={ImgesData[i].url}
                    className="w-100 shadow-1-strong rounded mb-4"
                    alt="Fertilizer"
                  />
                </div>
              })} */}

              
              
              <div className="col-lg-6"  data-bs-toggle="modal" data-bs-target="#exampleModalPhoto">
                <img
                  style={{ width: "100%" }}
                  src={p5}
                  className="w-100 shadow-1-strong rounded mb-4"
                  alt="projects"
                />
              </div>
              <div className="col-lg-6"  data-bs-toggle="modal" data-bs-target="#exampleModalPhoto">
                <img
                  style={{ width: "100%" }}
                  src={p3}
                  className="w-100 shadow-1-strong rounded mb-4"
                  alt="projects"
                />
              </div>
              <div className="col-lg-6"  data-bs-toggle="modal" data-bs-target="#exampleModalPhoto">
                <img
                  style={{ width: "100%" }}
                  src={p3}
                  className="w-100 shadow-1-strong rounded mb-4"
                  alt="projects"
                />
              </div>
              <div className="col-lg-6"  data-bs-toggle="modal" data-bs-target="#exampleModalPhoto">
                <img
                  style={{ width: "100%" }}
                  src={p6}
                  className="w-100 shadow-1-strong rounded mb-4"
                  alt="projects"
                />
              </div>
            </div>
            {/* Gallery */}
          </div>

          <div className="modal fade exampleModalPhoto" id="exampleModalPhoto" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                {/* <img src={ImgesData[0].url} /> */}
                <img src={p3} alt="projects" />
                <div id="carouselExampleIndicators" className="carousel slide">
                  <div className="carousel-inner">
                    <div style={{ borderTop: "2px solid gray" }} className="carousel-item active">
                      <img src={p4} alt="projects" />
                      {/* <img src={ImgesData[0].url} alt="projects" /> */}
                    </div>
                    <div className="carousel-item">
                      {/* <img src={ImgesData[0].url} alt="projects"/> */}
                      <img src={p6} alt="projects"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



      </section>
    </>
  );
}

export default PhotoGallery;

import React from "react";
import {FaAddressBook} from "react-icons/fa"
import {BsFillPhoneFill} from "react-icons/bs"
import {MdEmail} from "react-icons/md"
import {Ri24HoursFill} from "react-icons/ri"
import { Link } from "react-router-dom";

function ContactAddress() {
  return (
    <>
      <section className="connectSec p-30">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <div className="feature-box text-center">
                <div className="feature-box-content">
                    <FaAddressBook />
                  <h4>Address</h4>
                  <p>98B, 2nd Floor, Namberdar
                  Estate, New Friends Colony</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="feature-box text-center">
                
                <div className="feature-box-content">
                    <BsFillPhoneFill />
                  <h4>Phone Number</h4>
                  <p><a href="tel:+91 8851 74 6286">+91 8851 74 6286</a></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="feature-box text-center">
                <div className="feature-box-content">
                    <MdEmail />
                  <h4>E-mail Address</h4>
                  <p><Link to="mailto:seoabaris@gmail.com">seoabaris@gmail.com</Link></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="feature-box text-center">
                <div className="feature-box-content">
                    <Ri24HoursFill />
                  <h4>Working Days/Hours</h4>
                  <p>Mon - Sun / 9:00AM - 8:00PM</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactAddress;

import React from "react";

function ContactInfo() {
  return (
    <>
      <section className="contactContainer p-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contactInfo">
                <h4 className="ls-n-25 m-b-1">Contact Info</h4>
                <p>
                Unity Infra Real Estate stands for excellence and luxury in residential and commercial properties. It was incorporated in ----- with its focus on construction and development of residential, commercial . To grow its footprint internationally, and to give wings to its global ambitions, Unity Infra branched .
                </p>
                <p>It is the company’s mission to deliver value creating spaces, and its endeavours epitomise this philosophy. It has delivered iconic commercial developments of over ----- sq. ft. in Haryana namely – One Unity Infra Centre & Unity Infra Finance Centre and in the residential segment – Unity Infra Sky, Mumbai and Greens Panvel, apart from delivering projects in -------.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactInfo;

import React, { useState } from "react";
import fertilizer1 from "../../../../../assets/img/products/1.jpg";
import fertilizer2 from "../../../../../assets/img/products/2.jpg";

import Rating from "../../../../../shared/rating/Rating";

import { RxCross1 } from "react-icons/rx";
import { BsFillCartFill, BsFillEyeFill, BsFillSuitHeartFill, } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";
import { AiTwotoneStar } from "react-icons/ai";

import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useGetProductsQuery } from "../../../../products/productSlice";
import QuiekViewModal from "../../../../../pages/QueikViewModal/QuiekViewModal";
import { FcList } from "react-icons/fc";
import { productIData } from "../../../../../pages/products/mockData";
import { GoLocation } from "react-icons/go";

function RelatedProduct({ productData }) {
  const { data, error, isLoading } = useGetProductsQuery();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    arrows: false,
    autoplaySpeed: 3000,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

    ]
  };


  const [modelDataId, setModelDataId] = useState(null)

  const [show, setShow] = useState(false);

  const curr = window.localStorage.getItem('currencySym')
  const currencySymbol = curr ? curr : 'ZK'

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setModelDataId(id)
    setShow(true)
  };


  return (
    <>
      <Slider {...settings}>
        {productIData?.data?.map((item) => {
          return (
            <div className="col-lg-3 col-md-6 col-sm-12" key={item._id}>
              <div className="featuredInfo">
                <div className="featuredFigure">
                  <div className="featuredImg" >
                    <div className="spBorder">
                      <div className="">
                        <div className="categoryBody">
                          <div className="singleProducts m-0">
                            <Link to="/products" className="protiesfSale" style={{ backgroundImage: `url(${item.url})`, display: "block" }}>
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="star">
                                  <AiTwotoneStar />
                                </div>
                                <div className="sales">
                                  FOR SALE
                                </div>
                              </div>
                            </Link>
                          </div>

                        </div>

                      </div>
                    </div>
                    {<div className="quickView">
                      <ul>
                        <li className="viewProduct">
                          {/* <button
                          className="quick_view_btn"
                          onClick={(e) => {
                            handleShow(item._id)
                          }}
                        >
                          <BsFillEyeFill />
                        </button> */}
                          <Link to={`/product/${item._id}`}
                            className="quick_view_btn">
                            <BsFillEyeFill />
                          </Link>
                        </li>
                        <li className="addProduct">
                          <Link to="/products">
                            <FcList />
                          </Link>
                        </li>
                      </ul>
                    </div>}
                  </div>
                  <ul className="hotList">
                    <li>
                      <Link to="/products">Sale</Link>
                    </li>
                    <li>
                      <Link to="/products">-24%</Link>
                    </li>
                  </ul>
                </div>
                <div className="featuredContent">
                  <div className="d-flex align-items-center justify-content-between my-2">
                    <div class="apartText m-0">Apartment</div>
                    <div class="heartWish"><BsFillSuitHeartFill />
                    </div>
                  </div>
                  <div className="">
                    <span className=""><b><GoLocation /></b></span><p className="d-inline">SONIPAT,HARYANA</p>
                  </div>
                  <div className="seprated">
                    <span className="">Bedrooms:</span><span className="d-inline">3</span>
                    &nbsp;
                    <span className="">Bathrooms:</span><span className="d-inline">3</span>
                    &nbsp;
                    <span className="">Size:</span><span className="d-inline">1500ft<sup>2</sup></span>
                  </div>
                </div>
                {/* <div className="rateDigit">
                    <span className="cross">{currencySymbol} 59.00</span>
                    <span className="currentPrice">{currencySymbol}87</span>
                  </div>
                  <div className="buyNowInfo">
                    <Link
                      to="/cart"
                      className="btn btn-primary addCart"
                    >
                      <FiShoppingCart />
                      Add Cart
                    </Link>

                    <Link
                      to="/"
                      className="btn btn-primary buyNow"
                    >
                      <BsFillCartFill />
                      Buy Now
                    </Link>
                  </div>
                  <div className="productDesc">
                    <p>
                      CAN(Calcium ammonium nitrate: N(27), P(0), K(0), S(0),
                      Zn(0), Ca(3.2) Dosage: 5-6 gm per plant. Time of
                      Application: During vegetative stage. Use: Top dressing
                      containing calcium
                    </p>
                  </div> */}

                <div className="featuredOption">
                  <select defaultValue={"DEFAULT"}>
                    <option value="DEFAULT Option">Select Option</option>
                    <option value="one">One</option>
                    <option value="two">Two</option>
                  </select>
                </div>
              </div>
            </div>
          );
        })}
      </Slider >

      {
        modelDataId && (
          <QuiekViewModal modelDataId={modelDataId} show={show} onHide={handleClose} size="xl"
            centered />
        )
      }
    </>
  );
}

export default RelatedProduct;
